import axios from '@/axios'

const state = {
  compatibleDevices: null,
  exchangeRates: null,
  faqs: null,
  geolocation: null,
  instructions: null,
  popularCountries: null,
  supportedCurrencies: null
}

const mutations = {
  setSettings (state, settings) {
    Object.keys(settings).forEach(key => {
      state[key] = settings[key]
    })
  }
}

const actions = {
  async fetchSettings (context) {
    let url = '/api/settings'
    let response = await axios.get(url)
    let settings = response.data
    context.commit('setSettings', settings)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
