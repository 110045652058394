<template>
  <div class='collapse'>
    <div
      :class='{ "opened": opened }'
      @click='opened = !opened'>
      <slot name='header'/>
    </div>
    <transition name='col'
      @enter='animateEnter'
      @leave='animateLeave'>
      <div class='col'
        v-if='opened'>
        <slot/>
      </div>
    </transition>
  </div>
</template>

<script>
  import anime from 'animejs'

  export default {
    data: () => ({
      opened: false
    }),

    methods: {
      animateEnter (element, done) {
        anime({
          targets: element,
          height: element.scrollHeight,
          duration: 500,
          easing: 'easeInOutCubic',
          complete () {
            element.style.height = 'auto'
            done()
          }
        })
      },

      animateLeave (element, done) {
        anime({
          targets: element,
          height: [element.offsetHeight, '0px'],
          duration: 500,
          easing: 'easeInOutCubic',
          complete () {
            element.style.height = ''
            done()
          }
        })
      }
    }
  }
</script>

<style lang='scss' scoped>
  .col {
    @apply
      overflow-hidden
      h-0;
  }
</style>
