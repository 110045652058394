export default {
  path: '/info',
  component: {
    render: h => h('router-view')
  },
  children: [
    {
      path: 'about-us',
      name: 'about-us',
      component: () => import('@/pages/about-us')
    },
    {
      path: 'compatible-devices',
      name: 'compatible-devices',
      component: () => import('@/pages/compatible-devices')
    },
    {
      path: 'contact-us',
      name: 'contact-us',
      component: () => import('@/pages/contact-us')
    },
    {
      path: 'faqs',
      name: 'faqs',
      component: () => import('@/pages/faqs')
    },
    {
      path: 'how-esim-works',
      name: 'how-esim-works',
      component: () => import('@/pages/how-esim-works')
    },
    {
      path: 'privacy-policy',
      name: 'privacy-policy',
      component: () => import('@/pages/privacy-policy')
    },
    {
      path: 'terms-conditions',
      name: 'terms-conditions',
      component: () => import('@/pages/terms-conditions')
    }
  ]
}
