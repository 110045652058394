<template>
  <div class='progress'>
    <div class='pgb'
      :style='{ width: `${value}%` }'>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      value: Number
    }
  }
</script>

<style lang='scss' scoped>
  .progress {
    @apply
      h-8
      bg-gray-300
      rounded-full;
  }

  .pgb {
    @apply
      h-full
      bg-primary
      rounded-full;
  }
</style>
