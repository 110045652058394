<template>
  <label class='checkbox'>
    <transition name='che'
      mode='out-in'>
      <i class='che icon'
        v-text='checked ? "check_box" : "check_box_outline_blank"'
        :class='{ "checked": checked }'
        :key='checked'>
      </i>
    </transition>
    <slot/>
    <input class='inp'
      type='checkbox'
      :checked='checked'
      @change='$emit("change", $event.target.checked)'>
  </label>
</template>

<script>
  export default {
    model: {
      prop: 'checked',
      event: 'change'
    },

    props: {
      checked: Boolean
    }
  }
</script>

<style lang='scss' scoped>
  .checkbox {
    @apply
      flex
      cursor-pointer
      select-none;
  }

  .che {
    @apply
      mr-16
      text-gray-700;

    &.checked {
      @apply text-primary;
    }

    &-enter-active,
    &-leave-active {
      @apply
        transition
        duration-200
        ease-in-out;
    }

    &-enter,
    &-leave-to {
      @apply opacity-50;
    }
  }

  .inp {
    @apply hidden;
  }
</style>
