import axios from '@/axios'

const actions = {
  async sendContact (context, { contact }) {
    let url = '/api/contact'
    let response = await axios.post(url, contact)
    return response.data
  }
}

export default {
  namespaced: true,
  actions
}
