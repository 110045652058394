import axios from '@/axios'

const state = {
  profile: null,
  cards: null,
  esims: null
}

const mutations = {
  setProfile (state, profile) {
    state.profile = profile
  },

  setCards (state, cards) {
    state.cards = cards
  },

  setEsims (state, esims) {
    state.esims = esims
  }
}

const actions = {
  async fetchProfile (context) {
    let url = '/api/me/profile'
    let response = await axios.get(url)
    let profile = response.data
    context.commit('setProfile', profile)
  },

  async updateProfile (context, { profile }) {
    let url = '/api/me/profile'
    let response = await axios.post(url, profile)
    profile = response.data
    context.commit('setProfile', profile)
  },

  async fetchCards (context) {
    let url = '/api/me/cards'
    let response = await axios.get(url)
    let cards = response.data
    context.commit('setCards', cards)
  },

  async createCardIntent () {
    let url = '/api/me/cards'
    let response = await axios.post(url)
    return response.data
  },

  async removeCard (context, { id }) {
    let url = `/api/me/cards/${id}`
    let response = await axios.delete(url)
    let cards = response.data
    context.commit('setCards', cards)
  },

  async fetchEsims (context) {
    let url = '/api/me/esims'
    let response = await axios.get(url)
    let esims = response.data
    context.commit('setEsims', esims)
  },

  async getEsim (context, { id }) {
    let url = `/api/me/esims/${id}`
    let response = await axios.get(url)
    return response.data
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
