import firebase from '@/firebase'

const auth = firebase.auth()

const subscribeUserState = (function () {
  let unsubscribe
  return function (callback) {
    if (unsubscribe) unsubscribe()
    unsubscribe = auth.onAuthStateChanged(callback)
  }
})()

const getAuthProvider = function (id) {
  switch (id) {
    case 'apple.com':
      return new firebase.auth.OAuthProvider('apple.com')
    case 'facebook.com':
      return new firebase.auth.FacebookAuthProvider()
    case 'google.com':
      return new firebase.auth.GoogleAuthProvider()
  }
}

const state = {
  user: null
}

const mutations = {
  setUser (state, user) {
    state.user = user
  }
}

const actions = {
  getUser (context) {
    return new Promise(resolve => {
      subscribeUserState(user => {
        context.commit('setUser', user)
        resolve(user)
      })
    })
  },

  createUser (context, { email, password }) {
    return auth.createUserWithEmailAndPassword(email, password)
  },

  signInWithEmail (context, { email, password }) {
    return auth.signInWithEmailAndPassword(email, password)
  },

  signInWithRedirect (context, { providerId }) {
    let provider = getAuthProvider(providerId)
    return auth.signInWithRedirect(provider)
  },

  getRedirectResult () {
    return auth.getRedirectResult()
  },

  fetchSignInMethod (context, { email }) {
    return auth.fetchSignInMethodsForEmail(email)
  },

  sendPasswordResetEmail (context, { email }) {
    return auth.sendPasswordResetEmail(email)
  },

  signOut () {
    return auth.signOut()
  },

  linkWithCredential (context, { json }) {
    let credential = firebase.auth.AuthCredential.fromJSON(json)
    return auth.currentUser.linkWithCredential(credential)
  },

  linkWithRedirect (context, { providerId }) {
    let provider = getAuthProvider(providerId)
    return auth.currentUser.linkWithRedirect(provider)
  },

  unlink (context, { providerId }) {
    return auth.currentUser.unlink(providerId)
  },

  reauthenticate (context, { password }) {
    let credential = firebase.auth.EmailAuthProvider
      .credential(auth.currentUser.email, password)
    return auth.currentUser.reauthenticateWithCredential(credential)
  },

  updatePassword (context, { newPassword }) {
    return auth.currentUser.updatePassword(newPassword)
  },

  sendEmailVerification () {
    return auth.currentUser.sendEmailVerification()
  },

  refreshIdToken () {
    return auth.currentUser.getIdToken(true)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
