<template>
  <div class='snackbar'>
    <transition name='sna'>
      <div class='sna'
        v-if='visible'>
        <slot/>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    props: {
      duration: Number
    },

    data: () => ({
      visible: false
    }),

    destroyed () {
      this.hide()
    },

    methods: {
      show () {
        this.hide()
        this.$nextTick(() => {
          this.visible = true
          this.timeoutId = setTimeout(() => {
            this.hide()
          }, this.duration)
        })
      },

      hide () {
        this.visible = false
        clearTimeout(this.timeoutId)
      }
    }
  }
</script>

<style lang='scss' scoped>
  .snackbar {
    @apply absolute;
  }

  .sna {
    max-width: 480px;
    @apply
      fixed
      inset-x-24
      top-24
      z-800
      m-auto
      bg-white
      rounded
      shadow-lg;

    &-enter-active,
    &-leave-active {
      @apply
        transition
        duration-200
        ease-in-out;
    }

    &-enter,
    &-leave-to {
      @apply
        opacity-0
        transform
        -translate-y-full;
    }
  }

  @screen desktop {
    .sna {
      @apply
        top-auto
        bottom-24;

      &-enter,
      &-leave-to {
        @apply translate-y-full;
      }
    }
  }
</style>
