<template>
  <div class='select'>
    <div class='bel'
      v-if='label'>
      {{ label }}
    </div>
    <select class='sel'
      :class='{ "empty": !value, "invalid": error }'
      :value='value'
      :disabled='disabled'
      @change='$emit("input", $event.target.value)'>
      <option
        value=''
        v-if='placeholder'>
        {{ placeholder }}
      </option>
      <slot/>
    </select>
    <div class='ero'
      v-if='error'>
      {{ error }}
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      label: String,
      placeholder: String,
      value: String,
      disabled: Boolean,
      error: String
    }
  }
</script>

<style lang='scss' scoped>
  .bel {
    @apply
      mb-4
      text-sm
      text-gray-700;
  }

  .sel {
    height: 40px;
    @apply
      block
      px-16
      w-full
      min-w-0
      bg-white
      border-transparent
      border
      rounded
      transition
      duration-200
      ease-in-out
      appearance-none
      outline-none;

    &:focus,
    &:hover {
      @apply border-primary;
    }

    &.empty {
      @apply text-gray-500;
    }

    &.invalid {
      @apply border-red;
    }

    &:disabled {
      @apply
        text-gray-500
        pointer-events-none;
    }
  }

  .ero {
    @apply
      mt-4
      text-sm
      italic
      text-red;
  }
</style>
