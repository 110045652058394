import i18n from '@/i18n'

const state = {
  currency: 'USD'
}

const mutations = {
  setCurrency (state, currency) {
    state.currency = currency
  }
}

const actions = {
  async changeLocale (context, { locale }) {
    let message = await import(`@/assets/messages/${locale}.json`)
    i18n.setLocaleMessage(locale, message.default)
    i18n.locale = locale
  },

  changeCurrency (context, { currency }) {
    context.commit('setCurrency', currency)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
