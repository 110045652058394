export default {
  path: '/me',
  component: {
    render: h => h('router-view')
  },
  children: [
    {
      path: '',
      name: 'settings',
      component: () => import('@/pages/settings')
    },
    {
      path: 'account',
      name: 'account',
      component: () => import('@/pages/account')
    },
    {
      path: 'cards',
      name: 'cards',
      component: () => import('@/pages/cards')
    },
    {
      path: 'esims',
      component: {
        render: h => h('router-view')
      },
      children: [
        {
          path: '',
          name: 'esims',
          component: () => import('@/pages/esims')
        },
        {
          path: ':id',
          name: 'esim',
          component: () => import('@/pages/esim'),
          props: true
        },
        {
          path: ':id/manual',
          name: 'esim-manual',
          component: () => import('@/pages/esim-manual'),
          props: true
        },
        {
          path: ':id/qr-code',
          name: 'esim-qr-code',
          component: () => import('@/pages/esim-qr-code'),
          props: true
        }
      ]
    }
  ]
}
