<template>
  <keep-alive include='home-page'>
    <router-view :key='$route.fullPath'/>
  </keep-alive>
</template>

<script>
  export default {
    computed: {
      geolocation () {
        return this.$store.state.settings.geolocation
      }
    },

    mounted () {
      this.setLocale()
      this.setRouterGuard()
      this.$store.dispatch('auth/getUser')
      this.$store.dispatch('settings/fetchSettings')
    },

    methods: {
      setLocale () {
        let locale = localStorage.getItem('locale')
        if (locale) {
          this.$store.dispatch('app/changeLocale', { locale })
        }
      },

      setCurrency () {
        let currency = localStorage.getItem('currency') ||
          this.geolocation?.currency

        if (currency) {
          this.$store.dispatch('app/changeCurrency', { currency })
        }
      },

      setRouterGuard () {
        this.$router.beforeEach((to, from, next) => {
          this.$router.from = from
          next()
        })
      }
    },

    watch: {
      geolocation: {
        handler () {
          this.setCurrency()
        },
        immediate: true
      }
    }
  }
</script>

<style lang='scss'>
  @import 'tailwindcss/base';
  @import 'tailwindcss/utilities';
  @import '@/styles/base';
  @import '@/styles/fonts';
  @import '@/styles/icons';
  @import '@/styles/html';
</style>
