import Vue from 'vue'
import Router from 'vue-router'
import currencies from './routes/currencies'
import home from './routes/home'
import info from './routes/info'
import languages from './routes/languages'
import login from './routes/login'
import me from './routes/me'
import orders from './routes/orders'
import plans from './routes/plans'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    currencies,
    home,
    info,
    languages,
    login,
    me,
    orders,
    plans
  ],
  scrollBehavior (to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 }
  }
})
