import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import auth from './modules/auth'
import contact from './modules/contact'
import me from './modules/me'
import orders from './modules/orders'
import plans from './modules/plans'
import settings from './modules/settings'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    auth,
    contact,
    me,
    orders,
    plans,
    settings
  }
})
