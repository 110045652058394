<template>
  <div class='dialog'>
    <transition name='dia'>
      <div class='dia'
        v-if='visible'>
        <div class='tid'>
          <slot/>
        </div>
        <div>
          <slot name='actions'/>
        </div>
      </div>
    </transition>
    <transition name='bac'>
      <div class='bac'
        v-if='visible'>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    data: () => ({
      visible: false
    }),

    destroyed () {
      this.close()
    },

    methods: {
      open () {
        if (this.visible) return
        this.visible = true
        document.body.style.overflowY = 'hidden'
      },

      close () {
        if (!this.visible) return
        this.visible = false
        document.body.style.overflowY = ''
      }
    }
  }
</script>

<style lang='scss' scoped>
  .dialog {
    @apply absolute;
  }

  .dia {
    top: 15%;
    max-width: 480px;
    max-height: 70%;
    @apply
      fixed
      inset-x-24
      z-2400
      flex
      flex-col
      m-auto
      bg-white
      rounded;

    &-enter-active,
    &-leave-active {
      @apply
        transition
        duration-200
        ease-in-out;
    }

    &-enter,
    &-leave-to {
      @apply
        opacity-0
        transform
        scale-105;
    }
  }

  .tid {
    @apply
      overflow-y-auto
      flex-grow;
  }

  .bac {
    z-index: 2390;
    @apply
      fixed
      inset-0
      bg-black
      opacity-75;

    &-enter-active,
    &-leave-active {
      @apply
        transition
        duration-200
        ease-in-out;
    }

    &-enter,
    &-leave-to {
      @apply opacity-0;
    }
  }
</style>
