import axios from 'axios'
import firebase from './firebase'

axios.interceptors.request.use(async config => {
  let currentUser = firebase.auth().currentUser

  if (currentUser) {
    let idToken = await currentUser.getIdToken()
    config.headers['Authorization'] = `Bearer ${idToken}`
  }

  config.baseURL = process.env.VUE_APP_API_URL

  return config
})

export default axios
