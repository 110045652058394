import Vue from 'vue'
import Badge from './badge'
import Button from './button'
import Card from './card'
import Checkbox from './checkbox'
import Collapse from './collapse'
import Dialog from './dialog'
import Header from './header'
import Input from './input'
import Progress from './progress'
import Select from './select'
import Snackbar from './snackbar'
import Spinner from './spinner'
import Textarea from './textarea'

const elements = {
  'badge-element': Badge,
  'button-element': Button,
  'card-element': Card,
  'checkbox-element': Checkbox,
  'collapse-element': Collapse,
  'dialog-element': Dialog,
  'header-element': Header,
  'input-element': Input,
  'progress-element': Progress,
  'select-element': Select,
  'snackbar-element': Snackbar,
  'spinner-element': Spinner,
  'textarea-element': Textarea
}

Object.keys(elements).forEach(name => {
  Vue.component(name, elements[name])
})

export default {}
