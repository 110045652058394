import axios from '@/axios'

const state = {
  plans: null
}

const mutations = {
  setPlans (state, plans) {
    state.plans = plans
  }
}

const actions = {
  async fetchPlans (context) {
    let url = '/api/plans'
    let response = await axios.get(url)
    let plans = response.data
    context.commit('setPlans', plans)
  },

  async getPlan (context, { slug }) {
    let url = `/api/plans/${slug}`
    let response = await axios.get(url)
    return response.data
  },

  async buyPlan (context, { slug }) {
    let url = `/api/plans/${slug}/buy`
    let response = await axios.post(url)
    return response.data
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
