export default {
  path: '/plans',
  component: {
    render: h => h('router-view')
  },
  children: [
    {
      path: ':slug',
      name: 'plan',
      component: () => import('@/pages/plan'),
      props: true
    }
  ]
}
