import axios from '@/axios'

const actions = {
  async getOrder (context, { id }) {
    let url = `/api/orders/${id}`
    let response = await axios.get(url)
    return response.data
  },

  async payOrder (context, { id, cardId }) {
    let url = `/api/orders/${id}/pay`
    let response = await axios.post(url, { cardId })
    return response.data
  }
}

export default {
  namespaced: true,
  actions
}
