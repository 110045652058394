export default {
  path: '/orders',
  component: {
    render: h => h('router-view')
  },
  children: [
    {
      path: ':id',
      name: 'order',
      component: () => import('@/pages/order'),
      props: true
    }
  ]
}
